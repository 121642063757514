import { FETCH_USER_DATA, LOGIN_FAILED, LOGIN_SUCCCESS, LOGOUT_SUCCESS } from "../actionTypes";
import axios from "axios";
import Cookies from "js-cookie";
import { vlivdomain } from "../../Constrant/api";
import { notifyError, notifySuccess } from "../../Constrant/toastAlert";
const isProduction = process.env.NODE_ENV === 'production';

//Acion dispatcher for user login
export const userLogin = (res) => (dispatch) => {
  if (res.data.token) {
    dispatch({ type: "RELOAD", payload: false });
    dispatch({ type: FETCH_USER_DATA, payload: res.data });
    dispatch({ type: LOGIN_SUCCCESS, payload: res.data });
  } else {
    dispatch({ type: "RELOAD", payload: false });
    dispatch({ type: LOGIN_FAILED, payload: res.data });
    notifyError(res.data.message);
  }
};

export const userData = () => async (dispatch) => {
  try {
    const res = await axios.post(
      `${vlivdomain}/user/userdetails`,
      {},
      { withCredentials: true }
    );

    if (res.data.success) {
      dispatch({ type: FETCH_USER_DATA, payload: res.data });
    } else {
      console.log("User not logged in");
    }
  } catch (error) {
    if (error.response && error.response.status === 401) {
      console.log("Unauthorized: Please check your login status.");
    } else {
      console.log(`Error while fetching login user data: ${error}`);
    }
  }
};

export const userLogout = (navigate) => async (dispatch) => {
  try {
    const res = await axios.post(`${vlivdomain}/user/logout`);
    if (res.data.message == 'Loggedout Successfully') {
      Cookies.remove("companytoken", {
        domain: isProduction ? '.vliv.app' : 'localhost',
        path: '/'
      });
      Cookies.remove("userinfo", {
        domain: isProduction ? '.vliv.app' : 'localhost',
        path: '/'
      });

      dispatch({ type: LOGOUT_SUCCESS });
      dispatch({ type: FETCH_USER_DATA, payload: { token: "", userData: {} } });
      setTimeout(() => {
        notifySuccess(res.data.message);
        navigate("/");
      }, 500)
    } else {
      console.log("User not logged in");
    }
  } catch (error) {
    console.log(`Error while logout: ${error}`);

  }
}


